import { Stack, Text } from 'geist/components';
import { ArrowRight } from 'geist/new-icons/16';
import { pascalCase } from 'change-case';
import Link from 'next/link';
import type { Url } from 'next/dist/shared/lib/router/router';
import type { IconName } from 'geist/symbols';
import { Icon, iconNames } from 'geist/symbols';
import type { IconType, NewIconType } from 'geist/tmp/utils';
import { Icons } from 'geist/icons/all';
import { clsx } from 'clsx';
import type { BlogCardGrid } from '../../types';
import type { BlogCardType } from '../../types';
import styles from './card-grid.module.css';

export function BlogCardGrid({ cards }: BlogCardGrid): JSX.Element {
  return (
    <div
      className={clsx(styles.grid, {
        [styles.singleCard]: cards?.length === 1,
        [styles.oddCards]: cards && cards.length % 2 === 1,
      })}
    >
      {cards?.map((card) => (
        <BlogCard key={card.title} {...card} />
      ))}
    </div>
  );
}

export function BlogCard({
  title,
  description,
  link,
  icon,
}: BlogCardType): JSX.Element {
  let RenderedIcon: NewIconType | IconType | null = null;

  // This is gross and ugly code
  // because god knows what sort of icons we used to have,
  // this is solely to support older blog posts.

  if (icon) {
    RenderedIcon = Icons[pascalCase(icon) as keyof typeof Icons] as IconType;
  }

  if (icon && iconNames.includes(icon as IconName)) {
    // eslint-disable-next-line react/no-unstable-nested-components, react/function-component-definition, react/display-name
    RenderedIcon = () => <Icon name={icon as IconName} />;
  }

  const Comp = link ? Link : 'div';

  return (
    <Comp
      className={clsx(styles.card, {
        [styles.isLink]: Comp === Link,
      })}
      href={link as Url}
    >
      <Stack flex={1}>
        <Text
          className={styles.text}
          color="gray-900"
          lineHeight={24}
          size={16}
        >
          {RenderedIcon ? (
            <span>
              <RenderedIcon size={16} />
            </span>
          ) : null}
          <strong>{title}</strong>
          {description}
        </Text>
      </Stack>
      <Stack align="flex-start" justify={{ sm: 'center', md: 'flex-end' }}>
        {link ? (
          <span className={styles.arrowCta}>
            <ArrowRight color="var(--ds-gray-900)" />
          </span>
        ) : null}
      </Stack>
    </Comp>
  );
}
